import styled from "styled-components";
import LoginLeft from "../components/LoginLeft";
import RegisterRight from "../components/RegisterRight";
import { useGlobalContext } from "../contexts/GlobalContext";

const Container = styled.div`
  display: flex;
  height: 100lvh;
  overflow: scroll;
`;

function Register() {
  const { isMobile } = useGlobalContext();
  return (
    <Container>
      {!isMobile && <LoginLeft />}
      <RegisterRight />
    </Container>
  );
}

export default Register;
