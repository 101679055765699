import styled from "styled-components";
import LoginLeft from "../components/LoginLeft";
import LoginRight from "../components/LoginRight";
import { useGlobalContext } from "../contexts/GlobalContext";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

function Login() {
  const { isMobile } = useGlobalContext();
  return (
    <Container>
      {!isMobile && <LoginLeft />}
      <LoginRight />
    </Container>
  );
}

export default Login;
