import styled from "styled-components";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../utils/itemTypes";
import { Business, useGlobalContext } from "../contexts/GlobalContext";

const Card = styled.div`
  min-height: 2.9375rem;
  height: 2.9375rem;
  border: solid #e2eaf9 0.0625rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  cursor: pointer;
`;

const CardCircle = styled.div`
  min-height: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  width: 1.25rem;
  border-radius: 0.625rem;
  background: #111827;
  color: white;
  text-align: center;
`;

interface DragItem {
  id: string;
  status: string;
}

const GridCard = ({ business }: { business: Business }) => {
  const { setSelectedPageState, setSelectedBusiness } = useGlobalContext();
  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >({
    type: ItemTypes.BUSINESS,
    item: { id: business.businessName, status: business.status }, // Dragged item data
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <Card
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => {
        setSelectedPageState("viewOne");
        setSelectedBusiness(business.businessName);
      }}
    >
      <div>{business.businessName}</div>
      <CardCircle>
        {business.businessName.charAt(0).toLocaleUpperCase()}
      </CardCircle>
    </Card>
  );
};

export default GridCard;
