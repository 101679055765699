import styled from "styled-components";
import Button from "../assets/Buttons";

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SixthBox = styled.div`
  flex: 0 0 calc(100% / 6 - 2rem);
  @media (max-width: 1111px) {
    flex: 0 0 calc(25% - 2rem);
  }
  @media (max-width: 820px) {
    flex: 0 0 calc(50% - 1.5rem);
  }
`;

export const QuarterBox = styled.div`
  flex: 0 0 calc(25% - 2rem);
  @media (max-width: 820px) {
    flex: 0 0 calc(50% - 1.5rem);
  }
`;

export const ThirdBox = styled.span`
  flex: 0 0 calc(33.33% - 2rem);
  @media (max-width: 820px) {
    flex: 0 0 calc(50% - 1.5rem);
  }
`;

export const HalfBox = styled.div`
  flex: 0 0 calc(50% - 2rem);
  @media (max-width: 820px) {
    flex: 0 0 calc(100% - 2rem);
  }
`;

export const FullBox = styled.div`
  flex: 0 0 calc(100% - 2rem);
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @media (max-width: 820px) {
    justify-content: center;
    gap: 1rem;
  }
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  font-size: 1.0625rem;
  margin-bottom: 0.5rem;
`;

export const TextInput = styled.input`
  padding: 0.875rem 1rem;
  width: calc(100% - 2rem);
  height: calc(3.1875rem - 1.75rem);
  border-radius: 0.75rem;
  border: solid ${({ theme }) => theme.grey} 0.03125rem;
  resize: none;
  overflow-y: scroll;
`;

export const SelectInput = styled.select`
  width: 100%;
  height: 3.1875rem;
  padding: 1rem;
  color: ${({ theme }) => theme.grey};
  border-radius: 0.75rem;
`;

export const TextArea = styled.textarea`
  width: calc(100% - 2rem);
  height: 7.5rem;
  border-radius: 0.75rem;
  padding: 1rem;
  resize: none;
`;

export const Buttons = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-direction: row-reverse;
  gap: 1rem;
`;

export const SubmitButton = styled(Button)`
  width: 7.5625rem;
  height: 3.225rem;
`;
