import { styled } from "styled-components";

export const PrimaryText = styled.div`
  color: ${({ theme }) => theme.primary};
  display: inline;
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  display: block;
  text-align: center;
  padding: 2rem 0;
`;
