import { BACKEND_URL, STATUS_OPTIONS } from "./Constants";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { getFormFromPitch } from "../utils/helpers";
import { useMediaQuery } from "react-responsive";

interface GlobalContextType {
  gridView: boolean;
  setGridView: (value: boolean) => void;
  selectedPageState: "viewAll" | "add" | "edit" | "viewOne";
  setSelectedPageState: (value: "viewAll" | "add" | "edit" | "viewOne") => void;
  businesses: Business[];
  loggedIn: LoggedInType;
  setLoggedIn: (value: LoggedInType) => void;
  selectedBusiness: string;
  setSelectedBusiness: (value: string) => void;
  selectedSortBy: string;
  setSetelectedSortBy: (value: string) => void;
  formData: FormData;
  setFormData: (value: FormData) => void;
  moveBusiness: (
    draggedItem: {
      id: string;
      status: (typeof STATUS_OPTIONS)[number]["status"];
    },
    targetStatus: (typeof STATUS_OPTIONS)[number]["status"]
  ) => void;
  isMobile: boolean;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export type Business = {
  rep: string;
  lastModified: Date;
  businessName: string;
  phone: string;
  state: string;
  status: (typeof STATUS_OPTIONS)[number]["status"];
  pitchCount: number;
};

export type Pitch = {
  id: number;
  staff: string;
  businessName: string;
  contactName: string;
  position:
  | "other"
  | "dealerPrincipal"
  | "generalManager"
  | "marketingManager"
  | "reception"
  | "salesManager";
  contactType: "review" | "phone" | "email" | "intro" | "follow" | "inPerson";
  phone: string;
  email: string;
  website: string;
  country: string;
  street: string;
  city: string;
  state: string;
  postCode: string;
  dms: string;
  ledUsage: string;
  status: (typeof STATUS_OPTIONS)[number]["status"];
  reminderDuration:
  | "1 Hour"
  | "1 Day"
  | "2 Day"
  | "3 Day"
  | "1 Week"
  | "1 Month"
  | "Never";
  contactSentiment: "positive" | "neutral" | "negative";
  note: string;
  lastModified: Date;
};

type LoggedInType = {
  status: boolean;
  token: string;
};

export type FormData = {
  id: number;
  staff: string;
  businessName: string;
  contactName: string;
  position:
  | "other"
  | "dealerPrincipal"
  | "generalManager"
  | "marketingManager"
  | "reception"
  | "salesManager";
  contactType: "review" | "phone" | "email" | "intro" | "follow" | "inPerson";
  phone: string;
  email: string;
  website: string;
  country: string;
  street: string;
  city: string;
  state: string;
  postCode: string;
  dms: string;
  ledUsage: string;
  status: (typeof STATUS_OPTIONS)[number]["status"];
  reminderDuration:
  | "1 Hour"
  | "1 Day"
  | "2 Day"
  | "3 Day"
  | "1 Week"
  | "1 Month"
  | "Never";
  contactSentiment: "positive" | "neutral" | "negative";
  note: string;
};

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [gridView, setGridView] = useState(true);
  const [selectedPageState, setSelectedPageState] = useState<
    "viewAll" | "add" | "edit" | "viewOne"
  >("viewAll");
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [loggedIn, setLoggedIn] = useState<LoggedInType>({
    status: false,
    token: "",
  });
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedSortBy, setSetelectedSortBy] = useState("");
  const [formData, setFormData] = useState<FormData>({
    id: -1,
    staff: "",
    businessName: "",
    contactName: "",
    position: "other",
    contactType: "review",
    phone: "",
    email: "",
    website: "",
    country: "",
    street: "",
    city: "",
    state: "",
    postCode: "",
    dms: "Unknown",
    ledUsage: "Install",
    status: "Never",
    reminderDuration: "1 Week",
    contactSentiment: "positive",
    note: "",
  });
  const isMobile = useMediaQuery({ maxWidth: "767px" });
  const navigate = useNavigate();

  const moveBusiness = async (
    draggedItem: {
      id: string;
      status: (typeof STATUS_OPTIONS)[number]["status"];
    },
    targetStatus: (typeof STATUS_OPTIONS)[number]["status"]
  ) => {
    try {
      // get most recent and edit data
      if (targetStatus === draggedItem.status) {
        return;
      }
      const response = await axios.get(`${BACKEND_URL}/pitches`, {
        headers: {
          Authorization: `Bearer ${loggedIn.token}`,
          "Content-Type": "application/json",
        },
        params: {
          businessName: draggedItem.id,
        },
      });
      const mostRecentEntry = response.data.reduce(
        (latest: Pitch, current: Pitch) => {
          return new Date(latest.lastModified) > new Date(current.lastModified)
            ? latest
            : current;
        }
      );
      let form = getFormFromPitch(mostRecentEntry);
      form = { ...form, status: targetStatus };
      await axios.post(`${BACKEND_URL}/data/edit`, form, {
        headers: {
          Authorization: `Bearer ${loggedIn.token}`,
          "Content-Type": "application/json",
        },
      });
      getData(loggedIn.token);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getData = useCallback(
    async (token: string) => {
      if (!loggedIn.status) {
        return;
      }
      try {
        const data = await axios.get(`${BACKEND_URL}/businesses`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setBusinesses(data.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          // alert(`Error ${error.status}: ${error.message}`);
        }
        console.log(error);
      }
    },
    [loggedIn.status]
  );

  useEffect(() => {
    const doTokenCheck = async () => {
      try {
        const localToken = localStorage.getItem("token");
        if (localToken) {
          const response = await axios.post(
            `${BACKEND_URL}/auth`,
            {
              token: `Bearer ${localToken}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            loggedIn.status = true;
            loggedIn.token = localToken;
            getData(localToken);
            navigate("/dashboard");
          } else {
            localStorage.removeItem("token");
          }
        } else if (loggedIn.token) {
          const response = await axios.post(
            `${BACKEND_URL}/auth`,
            {
              token: `Bearer ${loggedIn.token}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            localStorage.setItem("token", loggedIn.token);
            getData(loggedIn.token);
            navigate("/dashboard");
          } else {
            loggedIn.status = false;
            loggedIn.token = "";
          }
        }
      } catch (error) {
        // alert("Session timed out. Please Login again");
        loggedIn.status = false;
        loggedIn.token = "";
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    doTokenCheck();
  }, [loggedIn, selectedPageState, navigate, getData]);

  useEffect(() => {
    let sorted = [...businesses];
    if (selectedSortBy === "Alphabetical") {
      sorted.sort((a, b) => {
        const nameA = a.businessName.toLowerCase();
        const nameB = b.businessName.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      sorted.sort(
        (a, b) =>
          new Date(b.lastModified).getTime() -
          new Date(a.lastModified).getTime()
      );
    }
    if (JSON.stringify(sorted) !== JSON.stringify(businesses)) {
      setBusinesses(sorted);
    }
  }, [selectedSortBy, businesses]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedPageState === "viewAll") {
      setFormData({
        id: -1,
        staff: "",
        businessName: "",
        contactName: "",
        position: "other",
        contactType: "review",
        phone: "",
        email: "",
        website: "",
        country: "",
        street: "",
        city: "",
        state: "",
        postCode: "",
        dms: "",
        ledUsage: "",
        status: "Never",
        reminderDuration: "1 Week",
        contactSentiment: "positive",
        note: "",
      });
    }
  }, [selectedPageState]);

  return (
    <GlobalContext.Provider
      value={{
        gridView,
        setGridView,
        selectedPageState,
        setSelectedPageState,
        businesses,
        loggedIn,
        setLoggedIn,
        selectedBusiness,
        setSelectedBusiness,
        selectedSortBy,
        setSetelectedSortBy,
        formData,
        setFormData,
        moveBusiness,
        isMobile,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Create a custom hook to access the context
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
