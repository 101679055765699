import styled from "styled-components";
import {
  FOOT_BAR_HEIGHT,
  NAV_BAR_HEIGHT,
  SIDE_BAR_WIDTH,
  TOOL_BAR_HEIGHT,
} from "../contexts/Constants";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useGlobalContext } from "../contexts/GlobalContext";
import Toolbar from "../components/Toolbar";
import PageGridDesktop from "./PageGridDesktop";
import PageAddDesktop from "./PageAddDesktop";
import PageEditDesktop from "./PageEditDesktop";
import PageListDesktop from "./PageListDesktop";
import PagePitchesDesktop from "./PagePitchesDesktop";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footbar from "../components/Footbar";

const Container = styled.div`
  width: 100vw;
  height: 100svh;
  overflow: hidden;
  display: flex;
`;

const Main = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) =>
    isMobile ? "100%" : `calc(100% - ${SIDE_BAR_WIDTH})`};
  height: 100%;
`;

const PageContainer = styled.div<{ isMobile: boolean }>`
  width: calc(100% - 3rem);
  height: ${({ isMobile }) =>
    isMobile
      ? `calc(100% - ${NAV_BAR_HEIGHT} - ${TOOL_BAR_HEIGHT} - ${FOOT_BAR_HEIGHT} - 2rem)`
      : `calc(100% - ${NAV_BAR_HEIGHT} - ${TOOL_BAR_HEIGHT} - 2rem)`};
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem;
`;

function PageLoader(isMobile: boolean) {
  const { gridView, selectedPageState } = useGlobalContext();
  if (selectedPageState === "viewAll" && gridView === true) {
    return <PageGridDesktop></PageGridDesktop>;
  } else if (selectedPageState === "viewAll" && gridView === false) {
    return <PageListDesktop />;
    // return <>{!isMobile ? <PageListDesktop></PageListDesktop> : "Mobile"}</>;
  } else if (selectedPageState === "add") {
    return <PageAddDesktop />;
    // return <>{!isMobile ? <PageAddDesktop></PageAddDesktop> : "Mobile"}</>;
  } else if (selectedPageState === "edit") {
    return <PageEditDesktop />;
    // return <>{!isMobile ? <PageEditDesktop></PageEditDesktop> : "Mobile"}</>;
  }
  return <PagePitchesDesktop />;
  // return (
  //   <>{!isMobile ? <PagePitchesDesktop></PagePitchesDesktop> : "Mobile"}</>
  // );
}

function Dashboard() {
  const { loggedIn, isMobile } = useGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn.status === false) {
      navigate("/login");
    }
  }, [navigate, loggedIn]);
  return (
    <Container>
      {!isMobile && <Sidebar />}
      <Main isMobile={isMobile}>
        <Navbar />
        <Toolbar />
        <PageContainer isMobile={isMobile}>
          {PageLoader(isMobile)}
        </PageContainer>
        {isMobile && <Footbar />}
      </Main>
    </Container>
  );
}

export default Dashboard;
