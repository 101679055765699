import Button, { CancelButton } from "../assets/Buttons";
import {
  dmsOptions,
  ledUsageOptions,
  reminderValues,
  STATUS_OPTIONS,
} from "../contexts/Constants";
import { useGlobalContext } from "../contexts/GlobalContext";
import {
  Container,
  SixthBox,
  Form,
  FullBox,
  HalfBox,
  Label,
  QuarterBox,
  SelectInput,
  SubmitButton,
  TextArea,
  TextInput,
  ThirdBox,
} from "./FormElements";

const FormPage = ({
  handleSubmit,
  edit,
}: {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  edit: boolean;
}) => {
  const { setFormData, formData, setSelectedPageState } = useGlobalContext();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ThirdBox>
          <Label htmlFor="PitchAddStaff">Staff*</Label>
          <TextInput
            name="staff"
            defaultValue={formData.staff}
            onChange={handleChange}
            placeholder="Add Staff"
            type="text"
            required
          ></TextInput>
        </ThirdBox>
        <ThirdBox>
          <Label htmlFor="PitchAddBusinessName">Business Name*</Label>
          <TextInput
            name="businessName"
            defaultValue={formData.businessName}
            onChange={handleChange}
            placeholder="Add Business Name"
            type="text"
            disabled={edit}
            required
          ></TextInput>
        </ThirdBox>
        <ThirdBox>
          <Label htmlFor="PitchAddContactName">Contact*</Label>
          <TextInput
            name="contactName"
            defaultValue={formData.contactName}
            onChange={handleChange}
            placeholder="Add Contact Name"
            type="text"
            required
          ></TextInput>
        </ThirdBox>
        <ThirdBox>
          <Label htmlFor="PitchAddPosition">Position*</Label>
          <SelectInput
            name="position"
            defaultValue={formData.position}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Add Positon
            </option>
            <option value="other">Other</option>
            <option value="dealerPrincipal">Dealer Principal</option>
            <option value="generalManager">General Manager</option>
            <option value="marketingManager">Marketing Manager</option>
            <option value="reception">Reception</option>
            <option value="salesManager">Sales Manager</option>
          </SelectInput>
        </ThirdBox>
        <ThirdBox>
          <Label htmlFor="PitchAddContactType">Contact Type*</Label>
          <SelectInput
            name="contactType"
            defaultValue={formData.contactType}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Add Contact Type
            </option>
            <option value="review">Review</option>
            <option value="phone">Phone</option>
            <option value="email">Email</option>
            <option value="intro">Intro</option>
            <option value="follow">Follow</option>
            <option value="inPerson">In Person</option>
          </SelectInput>
        </ThirdBox>
        <ThirdBox>
          <Label htmlFor="PitchAddPhone">Phone*</Label>
          <TextInput
            name="phone"
            defaultValue={formData.phone}
            onChange={handleChange}
            placeholder="Add Phone Number"
            type="number"
            required
          ></TextInput>
        </ThirdBox>
        <HalfBox>
          <Label htmlFor="PitchAddEmail">Email*</Label>
          <TextInput
            name="email"
            defaultValue={formData.email}
            onChange={handleChange}
            placeholder="Add Email"
            type="text"
            required
          ></TextInput>
        </HalfBox>
        <HalfBox>
          <Label htmlFor="PitchAddWebsite">Website*</Label>
          <TextInput
            name="website"
            defaultValue={formData.website}
            onChange={handleChange}
            placeholder="Add Website"
            type="text"
            required
          ></TextInput>
        </HalfBox>
        <QuarterBox>
          <Label htmlFor="PitchAddStreet">Street*</Label>
          <TextInput
            name="street"
            defaultValue={formData.street}
            onChange={handleChange}
            placeholder="Add Street"
            type="text"
            required
          ></TextInput>
        </QuarterBox>
        <QuarterBox>
          <Label htmlFor="PitchAddCity">City*</Label>
          <TextInput
            name="city"
            defaultValue={formData.city}
            onChange={handleChange}
            placeholder="Add City"
            type="text"
            required
          ></TextInput>
        </QuarterBox>
        <SixthBox>
          <Label htmlFor="PitchAddState">State*</Label>
          <TextInput
            name="state"
            defaultValue={formData.state}
            onChange={handleChange}
            placeholder="Add State"
            type="text"
            required
          ></TextInput>
        </SixthBox>
        <SixthBox>
          <Label htmlFor="PitchAddPostCode">Post Code*</Label>
          <TextInput
            name="postCode"
            defaultValue={formData.postCode}
            onChange={handleChange}
            placeholder="Add Post Code"
            type="text"
            required
          ></TextInput>
        </SixthBox>
        <SixthBox>
          <Label htmlFor="PitchAddCountry">Country*</Label>
          <TextInput
            name="country"
            defaultValue={formData.country}
            onChange={handleChange}
            placeholder="Add Country"
            type="country"
            required
          ></TextInput>
        </SixthBox>
        <QuarterBox>
          <Label htmlFor="PitchAddDms">DMS</Label>
          <SelectInput
            name="dms"
            defaultValue={formData.dms}
            onChange={handleChange}
          >
            <option value="">Select DMS</option>
            {dmsOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </SelectInput>
        </QuarterBox>
        <QuarterBox>
          <Label htmlFor="PitchAddLedUsage">LED Usage*</Label>
          <SelectInput
            name="ledUsage"
            defaultValue={formData.ledUsage}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Add LED Usage
            </option>
            {ledUsageOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </SelectInput>
        </QuarterBox>
        <QuarterBox>
          <Label htmlFor="PitchAddStatus">Status*</Label>
          <SelectInput
            name="status"
            defaultValue={formData.status}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Add Status
            </option>
            {STATUS_OPTIONS.map((status) => (
              <option value={status.status} key={status.status}>
                {status.status}
              </option>
            ))}
          </SelectInput>
        </QuarterBox>
        <HalfBox>
          <Label htmlFor="PitchAddReminderDuration">Follow Up Reminder*</Label>
          <SelectInput
            name="reminderDuration"
            defaultValue={formData.reminderDuration}
            id="PitchAddReminderDuration"
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Reminder Duration
            </option>
            {reminderValues.map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </SelectInput>
        </HalfBox>
        <HalfBox>
          <Label htmlFor="PitchAddContactSentiment">Contact Sentiment*</Label>
          <SelectInput
            name="contactSentiment"
            defaultValue={formData.contactSentiment}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Choose Sentiment
            </option>
            <option value="positive">Positive</option>
            <option value="neutral">Neutral</option>
            <option value="negative">Negative</option>
          </SelectInput>
        </HalfBox>
        <FullBox>
          <Label htmlFor="PitchAddNote">Note</Label>
          <TextArea
            name="note"
            defaultValue={formData.note}
            onChange={handleChange}
            placeholder="Add Notes on Rates Discuessed, ETC.."
          ></TextArea>
        </FullBox>
        <Button>
          <SubmitButton type="submit">Save</SubmitButton>
          <CancelButton
            type="button"
            onClick={() => setSelectedPageState("viewAll")}
          >
            Cancel
          </CancelButton>
        </Button>
      </Form>
    </Container>
  );
};

export default FormPage;
