import styled from "styled-components";
import { TextTerms } from "../assets/InputAssets";
import { useNavigate } from "react-router-dom";
import RegisterInputBox from "./RegisterInputBox";
import { useGlobalContext } from "../contexts/GlobalContext";
import { PrimaryText, Title } from "../assets/Texts";

const Container = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.white};
  height: 100lvh;
  width: ${({ isMobile }) => (isMobile ? "100vw" : "50vw")};
  display: flex;
  flex-direction: column;
  // justify-content: center;
  color: ${({ theme }) => theme.secondary};
  overflow: scroll;
`;

const Login = styled.u`
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

const Subtext = styled(TextTerms)`
  margin-bottom: 2.375rem;
`;

const DarkSubtext = styled.p`
  color: ${({ theme }) => theme.secondary};
  display: inline;
  font-size: 0.75rem;
`;

function RegisterRight() {
  const navigate = useNavigate();
  const { isMobile } = useGlobalContext();
  return (
    <Container isMobile={isMobile}>
      <Title>
        Simple<PrimaryText>Sales</PrimaryText>
      </Title>
      <RegisterInputBox />
      <Subtext>
        By signing In, you agree to our{" "}
        <DarkSubtext>
          <u>Terms of services</u> & <u>Privacy policy</u>
        </DarkSubtext>
      </Subtext>
      <Login onClick={() => navigate("/login")}>
        Already have an account? Sign In
      </Login>
    </Container>
  );
}

export default RegisterRight;
