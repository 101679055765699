import { BACKEND_URL } from "../contexts/Constants";
import axios, { AxiosError } from "axios";
import { useGlobalContext } from "../contexts/GlobalContext";
import FormPage from "../components/FormPage";

function PageAdd() {
  const { loggedIn, formData } = useGlobalContext();

  const { setSelectedPageState } = useGlobalContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sendData = async () => {
      try {
        const response = await axios.post(`${BACKEND_URL}/data`, formData, {
          headers: {
            Authorization: `Bearer ${loggedIn.token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          setSelectedPageState("viewAll");
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          // alert(`Error: ${error.response?.data.message}`);
          return;
        }
        console.log(error);
      }
    };
    sendData();
  };

  return <FormPage handleSubmit={handleSubmit} edit={false} />;
}

export default PageAdd;
