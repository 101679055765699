import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.secondary};
    font-family: 'Poppins', sans-serif;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    font-size: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
    background-color: #F9FAFB;
  }

  h1 {
    font-size: 3.4375rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  h6 {
    font-size: 0.75rem;
    display: inline;
    font-weight: normal;
  }

  p {
    font-size: 1rem;
  }

  a {
    font-size: 0.75rem;
  }

  u {
    font-size: 0.75rem;
  }

`;

export default GlobalStyle;
